var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

function once(fn) {
  var called = false;
  return function () {
    var args = [];

    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }

    if (!called) {
      called = true;
      fn.apply(void 0, args);
    }
  };
}

exports.once = once;
export default exports;